import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = sessionStorage.getItem('acc_jwt');
    const companyId = sessionStorage.getItem('company_id');


    if (currentUser && companyId) {
      // @ts-ignore
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser}`,
          company_id: companyId,
        }
      });


      // request = request.clone({ params: request.params.set('company_id', companyId) });

    }
    return next.handle(request);
  }
}
