import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private readonly translations = {
    //Plaza
    "email.0.contact": "emaili",
    "email.0.name": "email başlığını",
    "entity": "qurumu",
    "location.0.floor_id": "mərtəbə nömrəsini",
    "location.0.size": "mərtəbə sahəsini",
    "name": "adı",
    "phone.0.contact": "nömrəni ",
    "phone.0.name": "telefon başlığını",
    "user_email": "istifadəçi emaili",
    "floor_id": "mərtəbəni",
    "size": "ölçünü",
    "number": "nömrəni",
    "alias": "kart nömrəsini",
    "common_size": "ümumi sahəni",
    "image": "şəkili",
    "payment_terms.0.per_month": "yekun məbləği",
    "payment_terms.0.start_time": "qoşulduğu tarixi",
    "payment_terms.0.month_count": "ödəniş müddəti (ay)",
    "payment_terms.0.is_adv_payer": "ƏDV-yə cəlbi"

    //Insan Resurslari

  };

  getTranslation(key: string): string {
    return this.translations[key] || key;
  }
}

