import { AppService } from 'src/app/services/app/app.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslationService } from '../translation.service';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private appService: AppService,
    private router: Router,
    private translationService: TranslationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((evt: any) => {
        if (evt instanceof HttpResponse) {
          // pass url in component like : params['navigatorUrl'] = '/hr';
          // tslint:disable-next-line: triple-equals
          if (request.body && request.body.hasOwnProperty('navigatorUrl') && ((evt.status == 200) || (evt.status == 201))) {
            Swal.fire({
              title: 'Əməliyyat uğurla tamamlandı!',
              text: evt.body.responseMessage,
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Bağla',
            }).then((result) => {

              if ((result.value) && (request.body.navigatorUrl != 'no')) {
                this.router.navigate(['/admin' + request.body.navigatorUrl]);
              }
            });
          } else if (request.body && (request.body instanceof FormData)) {
            Swal.fire({
              title: 'Əməliyyat uğurla tamamlandı!',
              text: evt.body.responseMessage,
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Bağla',
            }).then((result) => {
              if ((result.value) && (request.body.get('navigatorUrl') != 'no')) {
                this.router.navigate(['/admin' + request.body.get('navigatorUrl')]);
              }
            });
            // tslint:disable-next-line: triple-equals
          } else if (evt.status != 200 && evt.status != 201) {
            Swal.fire({
              title: 'Əməliyyatda səhv!',
              text: evt.body.responseMessage,
              type: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Bağla',
            }).then((result) => {
              if (result.value) {
              }
            });
          }
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          try {
            if ([401, 403].indexOf(err.status) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
              sessionStorage.clear();
              this.router.navigate(['']);
              // location.reload();
            }
            else if (err.status === 404) {
              let message = 'Resurs tapılmadı';
              if (err.error.error === 'api.DoesNotDelete') {
                message = 'Görüş başladığına görə silmək mümkün deyil!';
              }
              Swal.fire({
                title: 'Əməliyyatda səhv',
                text: message,
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Bağla',
              }).then((result) => {
                if (result.value) {
                }
              });
            }
            else if (err.status == 422) {
              let message = '';
              // tslint:disable-next-line: forin
              for (let prop in err.error) {
                const translatedProp = this.translationService.getTranslation(prop);
                message = 'Zəhmət olmasa ' + translatedProp  + ' düzgün daxil edin';
              }
              if (err.error.error === 'apiResponse.reservationTimeError' || err.error.error === 'apiResponse.alreadyCantUpdate') {
                message = 'Rezerv ancaq cari zamandan sonra əlavə edilə bilər';
              }
              if (err.error.error === 'api.DoesNotFinish') {
                message = 'Görüşü başlamadan bitirmək mümkün deyil!';
              }
              if (err.error.error === 'apiResponse.cardIsUsed') {
                message = 'Bu kart nömrəsi artıq istifadə edilmişdir';
              }
              if (err.error.error === 'apiResponse.useUpdateOrChangeFloorId') {
                message = 'Zəhmət olmasa mərtəbəni dəyişin';
              }
              if (err.error.error === 'alias already exists') {
                message = 'Bu kart nömrəsi mövcuddur';
              }
              if(err.error.error === 'apiResponse.unProcess'){
                switch(true){
                  case request.url.includes('/plaza/floors/destroy/'):
                  message = 'Mərtəbənin müqaviləsi olduğuna görə silmə əməliyyatını həyata keçirmək mümkün deyil!';
                  break;
                }
              }
              console.log(request);
              Swal.fire({
                title: 'Əməliyyatda səhv',
                text: message,
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Bağla',
              }).then((result) => {
                if (result.value) {
                }
              });
            }
            else {
              Swal.fire({
                title: 'Əməliyyatda səhv',
                text: err.message,
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Bağla',
              }).then((result) => {
                if (result.value) {
                }
              });
            }

          } catch (e) {
            const error = err.error.message || err.statusText;
            return throwError(error);
          }
        }
        return of(err);
      }));
  }
}

