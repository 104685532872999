import { Injectable, Injector } from "@angular/core";
import { HttpsService } from "../https/https.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/index";

@Injectable({
  providedIn: "root",
})
export class AppService extends HttpsService {
  public LOGIN = "auth/login";
  public FORGOT = "auth/forgot";
  public RESET_PASSWORD = "auth/reset/password";
  public LOGOUT = "oauth/logout";
  public USERS = "users";
  public PROFILE = "profile";
  public DOC_STATS = "esd/statistics/documents";
  public MODULE_PERMISSIONS = "permissions/module";
  public TASK_MANAGEMENT_PROJECTS = "task-manager/project";
  public USERS_CURRENT_LOGIN = "users/getCurrentLogin";
  public UNREAD_NOTIFICATIONS = 'notification/unreadnotification'
  public READ_NOTIFICATIONS = 'notification/readnotification'
  public READ_NOTIFICATION_LIST= 'notification/readnotificationlist'
  public ALL_NOTIFICATIONS= 'notification/allnotification'
  constructor(public http: HttpClient) {
    super();
  }

  public getUnreadNotification(params: any = {}): Observable<any> {
    return this.get(this.http, this.UNREAD_NOTIFICATIONS, params);
  }
  public getReadNotificationList(params:any={}):Observable<any>{
    return this.get(this.http, this.READ_NOTIFICATION_LIST, params)
  }
  public readNotification(params: any={}): Observable<any>{
    return this.post(this.http, this.READ_NOTIFICATIONS, params)
  }

  public getAllNotification(params: any={}): Observable<any>{
    return this.get(this.http, this.ALL_NOTIFICATIONS, params)
  }

  public login(params: any = {}): Observable<any> {
    return this.post(this.http, this.LOGIN, params);
  }
  public logout(params: any = {}): Observable<any> {
    return this.post(this.http, this.LOGOUT, params);
  }

  public getProfile(params: any = {}): Observable<any> {
    return this.get(this.http, this.PROFILE, params);
  }

  public getCurrentLogin(params: any = {}): Observable<any> {
    return this.get(this.http, this.USERS_CURRENT_LOGIN, params);
  }

  public trackByFn(index, item) {
    return index; // or item.id
  }

  public getUsers(params: any = {}): Observable<any> {
    return this.get(this.http, this.USERS, params);
  }

  public getUserID(): Observable<any> {
    return JSON.parse(sessionStorage.getItem("user")).id;
  }

  clean(obj) {
    for (const propName in obj) {
      if (
        obj[propName] === "null" ||
        obj[propName] == "null" ||
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === [] ||
        obj[propName] === {}
      ) {
        delete obj[propName];
      }
      if (obj[propName] === "false") {
        obj[propName] = false;
      }
    }
    return obj;
  }

  cleanFormData(formData: any) {
    for (let pair of formData.entries()) {
      // tslint:disable-next-line: triple-equals
      if (pair[1] == "undefined" || pair[1] == undefined || pair[1] == null) {
        formData.delete(pair[0]);
      }
    }
    return formData;
  }

  /**
   * Global Get Method
   * @param params
   * @param route_url
   */
  public getItem(params: any = {}, route_url): Observable<any> {
    return this.get(this.http, `${route_url}/${params.id}`, null);
  }

  public sendEmail(params: any = {}): Observable<any> {
    return this.post(this.http, this.FORGOT, params);
  }

  public resetPassword(params: any = {}): Observable<any> {
    return this.post(this.http, this.RESET_PASSWORD, params);
  }

  public getList(params: any = {}, route_url): Observable<any> {
    this.clean(params);
    return this.get(this.http, route_url, params);
  }


  public updateProfileInfo(params: any = {}): Observable<any> {
    const formData = new FormData();
    formData.append("name", params.name);
    formData.append("surname", params.surname);
    formData.append("navigatorUrl", "no");
    const form = this.cleanFormData(formData);
    return this.post(this.http, this.PROFILE + "/" + "update", form);
  }


  public postForm(route_url: string, params): Observable<any> {
    return this.post(this.http, route_url, params);
  }
}
