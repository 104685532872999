<header class="main-header d-table tr-3s w-100p position-relative bg-white mb-30">
  <div class="d-row">
    <!--Nav Toggle-->
    <div class="d-cell hide-xl hide-lg border-right">
      <span class="nav-toggle hide-xl hide-lg float-left" aria-controls="sidebar-menu">
        <i aria-hidden="true"></i>
      </span>
    </div>
    <!--Logo-->
    <div class="d-cell v-align-top w-100p">
      <a [routerLink]="['/admin/home']" class="logo text-center position-relative" title="Ana panel">
        <img src="/assets/images/oneoffice.svg" alt="" width="205" height="50">
      </a>
    </div>
    <!--Right Col-->
    <div class="d-cell v-align-middle ml-auto mr-20">
      <div class="d-table w-100p">
        <ul class="d-row right-col type-none m-0 p-0">
          <li class="d-cell no-wrap" style="padding-right: 10px !important;">
            <mat-list [matMenuTriggerFor]="menu2" class="pt-0 pointer">
              <mat-list-item class="position-relative language-icon">
                <h3 matLine class="hide-sm hide-xs text-uppercase">az</h3>
              </mat-list-item>
            </mat-list>
            <mat-menu #menu2="matMenu">
              <mat-list dense>
                <mat-list-item class="pointer">
                  <img src="../../../../assets/images/svg/en.svg" alt="en">
                  <span mat-line class="text-uppercase">en</span>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item class="pointer">
                  <img src="../../../../assets/images/svg/ru.svg" alt="ru">
                  <span mat-line class="text-uppercase">ru</span>
                </mat-list-item>
              </mat-list>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
    <!--Right Col-->
    <div class="d-cell v-align-middle border-left">
      <div class="d-table w-100p">
        <ul class="d-row right-col type-none m-0 p-0">
          <li class="d-cell no-wrap">
            <mat-list [matMenuTriggerFor]="menu" class="pt-0 pointer">
              <mat-list-item>
                <img dense matListAvatar src="https://img.pngio.com/parent-directory-avatar-2png-avatar-png-256_256.png"
                     alt="Elvin Abbasov">
                <h3 matLine class="hide-sm hide-xs"></h3>
              </mat-list-item>
            </mat-list>
            <mat-menu #menu="matMenu">
              <mat-list dense>
                <mat-list-item>
                  <mat-icon mat-list-icon>no_encryption</mat-icon>
                  <span mat-line>Şifrəni Dəyiş</span>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon mat-list-icon>help_outline</mat-icon>
                  <span mat-line>Kömək</span>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item title="Çıxış">
                  <mat-icon mat-list-icon>power_settings_new</mat-icon>
                  <span mat-line>Çıxış</span>
                </mat-list-item>
              </mat-list>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

<span class="pb-10 text-center d-block mb-40 font-30 font-md-40 text-main"> Opppss! </span>

<div class="row sm-5 xs-5 mb-20">

  <div class="col as-12 xs-12 mb-15 d-flex justify-content-center pl-10 pr-10">
    <div class="position-relative">
      <div class="not-found-img">
        <img src="/assets/images/group_255.png" width="100%" height="100%" alt="">
      </div>
    </div>
  </div>

</div>

